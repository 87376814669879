var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1",attrs:{"tile":""}},[_c('v-card-text',[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'iban'),"label":_vm.$t('hr.employee.financial.iban'),"rules":"max:50","index":0,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.iban),callback:function ($$v) {_vm.$set(_vm.employeePayload, "iban", $$v)},expression:"employeePayload.iban"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'bankAccountNumber'),"label":_vm.$t('hr.employee.financial.bank_account_number'),"rules":"max:50","index":1,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.bankAccountNumber),callback:function ($$v) {_vm.$set(_vm.employeePayload, "bankAccountNumber", $$v)},expression:"employeePayload.bankAccountNumber"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'ticketNumber'),"label":_vm.$t('hr.employee.financial.ticket_number'),"rules":"max:50","index":2,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.ticketNumber),callback:function ($$v) {_vm.$set(_vm.employeePayload, "ticketNumber", $$v)},expression:"employeePayload.ticketNumber"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'bankBranch'),"label":_vm.$t('hr.employee.financial.bank_branch'),"rules":"max:50","index":3,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"outlined":"","dense":"","color":"tertiary","counter":"50","error-messages":errors},model:{value:(_vm.employeePayload.bankBranch),callback:function ($$v) {_vm.$set(_vm.employeePayload, "bankBranch", $$v)},expression:"employeePayload.bankBranch"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'healthInsurancePersonCount'),"label":_vm.$t('hr.employee.financial.health_insurance_person_count'),"rules":"integer|min_value:0","index":4,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.healthInsurancePersonCount),callback:function ($$v) {_vm.$set(_vm.employeePayload, "healthInsurancePersonCount", _vm._n($$v))},expression:"employeePayload.healthInsurancePersonCount"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'ticketPrice.amount')
                ? ((_vm.$helpers.get(_vm.employee, 'ticketPrice.amount')) + " " + (_vm.$t(
                    ("currency.codes." + (_vm.$helpers.get(_vm.employee, 'ticketPrice.code')))
                  )))
                : null,"label":_vm.$t('hr.employee.financial.ticket_price'),"rules":"min_value:0|max_value:999999","index":5,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick}},[[_c('div',{staticClass:"d-flex align-start"},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.employee.financial.ticket_price'),"slim":"","rules":"min_value:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.ticketPrice.amount),callback:function ($$v) {_vm.$set(_vm.employeePayload.ticketPrice, "amount", _vm._n($$v))},expression:"employeePayload.ticketPrice.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('currency.code'),"slim":"","rules":{
                    required: !!_vm.employeePayload.ticketPrice.amount
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('currency-picker',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"color":"tertiary","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.employeePayload.ticketPrice.code),callback:function ($$v) {_vm.$set(_vm.employeePayload.ticketPrice, "code", $$v)},expression:"employeePayload.ticketPrice.code"}})]}}],null,true)})],1)]],2)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'previousTicketPrice.amount')
                ? ((_vm.$helpers.get(_vm.employee, 'previousTicketPrice.amount')) + " " + (_vm.$t(
                    ("currency.codes." + (_vm.$helpers.get(_vm.employee, 'previousTicketPrice.code')))
                  )))
                : null,"label":_vm.$t('hr.employee.financial.previous_ticket_price'),"rules":"min_value:0|max_value:999999","index":6,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick}},[[_c('div',{staticClass:"d-flex align-start"},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.employee.financial.previous_ticket_price'),"slim":"","rules":"min_value:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.previousTicketPrice.amount),callback:function ($$v) {_vm.$set(_vm.employeePayload.previousTicketPrice, "amount", _vm._n($$v))},expression:"employeePayload.previousTicketPrice.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('currency.code'),"slim":"","rules":{
                    required: !!_vm.employeePayload.previousTicketPrice.amount
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('currency-picker',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"color":"tertiary","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.employeePayload.previousTicketPrice.code),callback:function ($$v) {_vm.$set(_vm.employeePayload.previousTicketPrice, "code", $$v)},expression:"employeePayload.previousTicketPrice.code"}})]}}],null,true)})],1)]],2)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'salary.amount')
                ? ((_vm.$helpers.get(_vm.employee, 'salary.amount')) + " " + (_vm.$t(
                    ("currency.codes." + (_vm.$helpers.get(_vm.employee, 'salary.code')))
                  )))
                : null,"label":_vm.$t('hr.employee.financial.salary'),"rules":"min_value:0|max_value:999999","index":7,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick}},[[_c('div',{staticClass:"d-flex align-start"},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.employee.financial.salary'),"slim":"","rules":"min_value:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.salary.amount),callback:function ($$v) {_vm.$set(_vm.employeePayload.salary, "amount", _vm._n($$v))},expression:"employeePayload.salary.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('currency.code'),"slim":"","rules":{
                    required: !!_vm.employeePayload.salary.amount
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('currency-picker',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"color":"tertiary","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.employeePayload.salary.code),callback:function ($$v) {_vm.$set(_vm.employeePayload.salary, "code", $$v)},expression:"employeePayload.salary.code"}})]}}],null,true)})],1)]],2)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'previousSalary.amount')
                ? ((_vm.$helpers.get(_vm.employee, 'previousSalary.amount')) + " " + (_vm.$t(
                    ("currency.codes." + (_vm.$helpers.get(_vm.employee, 'previousSalary.code')))
                  )))
                : null,"label":_vm.$t('hr.employee.financial.previous_salary'),"rules":"min_value:0|max_value:999999","index":8,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick}},[[_c('div',{staticClass:"d-flex align-start"},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.employee.financial.previous_salary'),"slim":"","rules":"min_value:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.previousSalary.amount),callback:function ($$v) {_vm.$set(_vm.employeePayload.previousSalary, "amount", _vm._n($$v))},expression:"employeePayload.previousSalary.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('currency.code'),"slim":"","rules":{
                    required: !!_vm.employeePayload.previousSalary.amount
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('currency-picker',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"color":"tertiary","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.employeePayload.previousSalary.code),callback:function ($$v) {_vm.$set(_vm.employeePayload.previousSalary, "code", $$v)},expression:"employeePayload.previousSalary.code"}})]}}],null,true)})],1)]],2)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'yearlyPremium.amount')
                ? ((_vm.$helpers.get(_vm.employee, 'yearlyPremium.amount')) + " " + (_vm.$t(
                    ("currency.codes." + (_vm.$helpers.get(_vm.employee, 'yearlyPremium.code')))
                  )))
                : null,"label":_vm.$t('hr.employee.financial.yearly_premium'),"rules":"min_value:0|max_value:999999","index":9,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick}},[[_c('div',{staticClass:"d-flex align-start"},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.employee.financial.yearly_premium'),"slim":"","rules":"min_value:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.yearlyPremium.amount),callback:function ($$v) {_vm.$set(_vm.employeePayload.yearlyPremium, "amount", _vm._n($$v))},expression:"employeePayload.yearlyPremium.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('currency.code'),"slim":"","rules":{
                    required: !!_vm.employeePayload.yearlyPremium.amount
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('currency-picker',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"color":"tertiary","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.employeePayload.yearlyPremium.code),callback:function ($$v) {_vm.$set(_vm.employeePayload.yearlyPremium, "code", $$v)},expression:"employeePayload.yearlyPremium.code"}})]}}],null,true)})],1)]],2)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'monthlyStoppage.amount')
                ? ((_vm.$helpers.get(_vm.employee, 'monthlyStoppage.amount')) + " " + (_vm.$t(
                    ("currency.codes." + (_vm.$helpers.get(_vm.employee, 'monthlyStoppage.code')))
                  )))
                : null,"label":_vm.$t('hr.employee.financial.monthly_stoppage'),"rules":"min_value:0|max_value:999999","index":10,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick}},[[_c('div',{staticClass:"d-flex align-start"},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.employee.financial.monthly_stoppage'),"slim":"","rules":"min_value:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.monthlyStoppage.amount),callback:function ($$v) {_vm.$set(_vm.employeePayload.monthlyStoppage, "amount", _vm._n($$v))},expression:"employeePayload.monthlyStoppage.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('currency.code'),"slim":"","rules":{
                    required: !!_vm.employeePayload.monthlyStoppage.amount
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('currency-picker',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"color":"tertiary","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.employeePayload.monthlyStoppage.code),callback:function ($$v) {_vm.$set(_vm.employeePayload.monthlyStoppage, "code", $$v)},expression:"employeePayload.monthlyStoppage.code"}})]}}],null,true)})],1)]],2)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'agi.amount')
                ? ((_vm.$helpers.get(_vm.employee, 'agi.amount')) + " " + (_vm.$t(
                    ("currency.codes." + (_vm.$helpers.get(_vm.employee, 'agi.code')))
                  )))
                : null,"label":_vm.$t('hr.employee.financial.agi'),"rules":"min_value:0|max_value:999999","index":11,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick}},[[_c('div',{staticClass:"d-flex align-start"},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.employee.financial.agi'),"slim":"","rules":"min_value:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.agi.amount),callback:function ($$v) {_vm.$set(_vm.employeePayload.agi, "amount", _vm._n($$v))},expression:"employeePayload.agi.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('currency.code'),"slim":"","rules":{
                    required: !!_vm.employeePayload.agi.amount
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('currency-picker',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"color":"tertiary","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.employeePayload.agi.code),callback:function ($$v) {_vm.$set(_vm.employeePayload.agi, "code", $$v)},expression:"employeePayload.agi.code"}})]}}],null,true)})],1)]],2)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'grossSalary.amount')
                ? ((_vm.$helpers.get(_vm.employee, 'grossSalary.amount')) + " " + (_vm.$t(
                    ("currency.codes." + (_vm.$helpers.get(_vm.employee, 'grossSalary.code')))
                  )))
                : null,"label":_vm.$t('hr.employee.financial.gross_salary'),"rules":"min_value:0|max_value:999999","index":12,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick}},[[_c('div',{staticClass:"d-flex align-start"},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.employee.financial.gross_salary'),"slim":"","rules":"min_value:0|max_value:999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal"}],staticStyle:{"max-width":"200px"},attrs:{"min":"0","outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(_vm.employeePayload.grossSalary.amount),callback:function ($$v) {_vm.$set(_vm.employeePayload.grossSalary, "amount", _vm._n($$v))},expression:"employeePayload.grossSalary.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('currency.code'),"slim":"","rules":{
                    required: !!_vm.employeePayload.grossSalary.amount
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('currency-picker',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"color":"tertiary","outlined":"","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.employeePayload.grossSalary.code),callback:function ($$v) {_vm.$set(_vm.employeePayload.grossSalary, "code", $$v)},expression:"employeePayload.grossSalary.code"}})]}}],null,true)})],1)]],2)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'netPay')
                ? _vm.$t('hr.employee.financial.pay_to_net')
                : _vm.$t('hr.employee.financial.pay_to_gross'),"label":_vm.$t('hr.employee.financial.salary_type'),"index":13,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-1 pt-0",attrs:{"row":"","mandatory":""},model:{value:(_vm.employeePayload.netPay),callback:function ($$v) {_vm.$set(_vm.employeePayload, "netPay", $$v)},expression:"employeePayload.netPay"}},[_c('v-radio',{attrs:{"color":"primary","value":true,"label":_vm.$t('hr.employee.financial.pay_to_net')}}),_c('v-radio',{attrs:{"color":"primary","value":false,"label":_vm.$t('hr.employee.financial.pay_to_gross')}})],1)]}}],null,true)})],1),_vm._l((_vm.employeePayload.objectAttributeValues),function(objectAttributeValue,idx){return [_c('v-col',{key:idx,staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":objectAttributeValue.value,"label":objectAttributeValue.name,"index":14 + idx,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(objectAttributeValue.value),callback:function ($$v) {_vm.$set(objectAttributeValue, "value", $$v)},expression:"objectAttributeValue.value"}})]}}],null,true)})],1)]})],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }